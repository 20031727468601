import React from 'react';
import { skills } from '../utils/constants';

function Skills() {
  return (
    <section className="skills" tabIndex="0">
      <div className="skills__container">
        <h2 className="skills__container__title">{skills.title}</h2>
        <div className="skills__container__wrapper">
          {
            skills.items.map((element, index) => 
            <button key={element.imgAlt} className="skills__container__wrapper__item" data-open={"skills-modal" + index}>
              <img src={element.imgPath} alt={element.imgAlt}></img>
              <p>{element.title}</p>
            </button>
            )}
        </div>
      </div>
    </section>
  );
}
  
export default Skills;