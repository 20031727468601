import React from 'react';

function Intro() {
  return (
    <section className="intro" tabIndex="0">
      <div className="intro__background"></div>
      <div className="intro__text-container">
        <h1 className="intro__text-container__name">Erick Cardiel</h1>
        <h2 className="intro__text-container__title">Software Engineer</h2>
      </div>
      <svg className="intro__svg-container" viewBox="0 0 263.07 394.61" preserveAspectRatio="xMinYMax meet">
        <polygon className="intro__svg-container__blue-triangle" points="0 394.61 0 0 263.07 263.07 131.53 394.61 0 394.61"></polygon>
        <polygon className="intro__svg-container__white-triangle" points="0 394.61 0 263.07 131.53 394.61 0 394.61"></polygon>
        <polygon className="intro__svg-container__white-triangle" points="131.53 394.61 197.3 328.84 263.07 394.61 131.53 394.61"></polygon>
      </svg>
	  </section>
  );
}

export default Intro;