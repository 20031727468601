export const main = {
    title: `Hello world, I'm Erick`
};

/* ======================================================= */

export const aboutMe = {
    title: `About Me`,
    content: `Computer Engineer with +2 year experience in Software Engineering. I’m responsible, self-taught and
    self-motivated, excellent social skills, always looking for something new to learn. 
    `,
    contact: {
        imgPath: `/assets/images/general/about-me_my-photo.png`,
        imgAlt: `Contact icon`,
        items: [{
            imgPath: `/assets/images/icons/about-me_email.svg`,
            imgAlt: `email icon`,
            text: `erick_cardiel96@hotmail.com`,
            url: `mailto:erick_cardiel96@hotmail.com`
        },
        {
            imgPath: `/assets/images/icons/about-me_telephone.svg`,
            imgAlt: `Phone icon`,
            text: `664-670-3565`,
            url: `tel:+526646703565`,
        },
        {
            imgPath: `/assets/images/icons/about-me_facebook.svg`,
            imgAlt: `Facebook icon`,
            text: `fb/Erk.crd`,
            url: `https://www.facebook.com/ErkGC96`
        },
        {
            imgPath: `/assets/images/icons/about-me_github.svg`,
            imgAlt: `Github icon`,
            text: `github/ErickCardiel`,
            url: `https://github.com/ErickCardiel`
        },
        {
            imgPath: `/assets/images/icons/about-me_linkedin.svg`,
            imgAlt: `linkedin icon`,
            text: `linkedIn/ErickCardiel`,
            url: `https://www.linkedin.com/in/ErickCardiel`
        }]
    }
};

/* ======================================================= */

export const skills = {
    title: `Skills`,
    items: [{
        title: `HTML5\nCSS3\nJavaScript EC6+`,
        imgPath: `/assets/images/icons/skills_html5.svg`,
        imgAlt: `HTML5 icon`,
        modal: {
            header: `HTML5, CSS3, JavaScript`,
            content: `Cross-browser compatibility
            SEO HTML Optimization
            Accessibility
            SASS/SCSS - BEM Methodology
            Responsive design
            Grid layouts
            Material-UI/Bootstrap
            Progressive web applications
            Javasript EC6+
            Typescript
            `,
            footer: `This is a test`
        }
    },
    {
        title: `React\nRedux\nSaga`,
        imgPath: `/assets/images/icons/skills_react.svg`,
        imgAlt: `React icon`,
        modal: {
            header: `React, Redux, Saga`,
            content: `React Routes
            React Context
            React Hooks
            React PropTypes
            Reac API Profiler
            Class/functional components
            Good understanding of reusable/atomic components
            Good understanding of component's lifecycle
            Saga effects`
            ,
        },
        footer: `Test`
    },
    {
        title: `Django`,
        imgPath: `/assets/images/icons/skills_django.svg`,
        imgAlt: `Django icon`,
        modal: {
            header: `Django`,
            content: `Django REST
            Architect, design and implement RESTful APIs
            Understanding of querysets
            Experience with standard Python modules/libraries
            `,
            // Capable of writing Class Based Views code
            // You are proficient enough to optimize relational databases (SQLite) and scale the Querysets
            // Build process for extraction, transformation, and loading of data from wide varieties of data sources (e.g., APIs, CSV, Excel, Other databases, etc.) using Python and SQL 
        },
        footer: `Test`
    },
    {
        title: `.NET`,
        imgPath: `/assets/images/icons/skills_dot-net.svg`,
        imgAlt: `.NET icon`,
        modal: {
            header: `.NET`,
            content: `ASP .NET MVC
            Architect, design and implement RESTful APIs            
            OOP best practices
            Understanding of LINQ querys
            `,
            // Experience with MongoDB and NoSQL database design, queries, and maintenance
        },
        footer: `Test`
    },
    {
        title: `AWS`,
        imgPath: `/assets/images/icons/skills_aws.svg`,
        imgAlt: `Amazon Web Services icon`,
        modal: {
            header: `Amazon Web Services`,
            content: `IAM
            EC2
            S3
            Lambda
            Dynamo DB
            API Gateway
            Route 53
            IOT Core
            SES
            Route 53
            Cloudwatch
            `,
            footer: `This is a test`,
        }
    },
    {
        title: `UI/UX`,
        imgPath: `/assets/images/icons/skills_ui-ux.svg`,
        imgAlt: `UI/UX`,
        modal: {
            header: `UI/UX`,
            content: `Good visual design skills 
            Good understanding of color palettes
            Adobe XD Protyping
            Adobe Photoshop
            Adobe Illustrator
            `,
            footer: `This is a test`,
        }
    }
    ]
};

/* ======================================================= */

export const employeeBackground = {
    experience: {
            titleSamsung: `Samsung Electronics`, 
            periodSamsung: `(2019 - 2020)`, 
            titleNtt:`NTT Data `,
            periodNtt:`(2020 - Currently)`,
            description: `Working as a Software Engineer I developed multiple web applications for browsers and Samsung smart products (Smart TV and Family Hub)`
    },
    education: {
        title: `Autonomous University of Baja California `, 
        degree:`Bachelor's Degree - Computer Engineer`,
        period:`2014-2018`,
        description: `Computer Engineer  with  strong  knowledge  in  Embedded Systems, Cloud Computing and Web Development`
    }
};

/* ======================================================= */

export const portfolio = {
    title: "Portfolio",
    elements: [    
    {
        website: null,
        content: [
            {title:"Project", description:`Profile Explorer`}, 
            {title:`Description`, description:`I designed and developed a web application to identify, extract and analyze user behavior data in Samsung devices`},
            {title:"Stack", description:`ReactJS, Redux, Saga, MaterialUI, AWS`}
        ],
        img_className: `portfolio__container__wrapper__item__profile-explorer`,
        img_main: `/assets/images/icons/portfolio_profile_explorer.svg`, 
        imgs: [`/assets/images/general/portfolio_profile_explorer_1.jpg`,
                `/assets/images/general/portfolio_profile_explorer_2.jpg`,
                `/assets/images/general/portfolio_profile_explorer_3.jpg`]
    },
    {
        website: null,
        content: [
            {title:"Project", description:`Privacy Choices`}, 
            {title:`Description`, description:`I developed new features, fixed errors, and upgraded interfaces to the Privacy Choices application in Samsung Smart TVs`},
            {title:"Stack", description:`ReactJS, Redux, Saga, TypeScript, Tizen SDK`}
        ],
        img_className: `portfolio__container__wrapper__item__privacy-choices`,
        img_main: `/assets/images/icons/portfolio_privacy_choices.svg`,
        imgs: [ `/assets/images/general/portfolio_privacy_choices_1.jpg`,
                `/assets/images/general/portfolio_privacy_choices_2.jpg`,
                `/assets/images/general/portfolio_privacy_choices_3.jpg`,
                `/assets/images/general/portfolio_privacy_choices_4.jpg`,]
    },
    {
        website: null,
        content: [
            {title:"Project", description:`Order Release`}, 
            {title:`Description`, description:`I developed new features, fixed errors, and improved performance to Order Release LATAM web application`},
            {title:"Stack", description:`ReactJS, Ant Design, .NET, PostgreSQL`}
        ],
        img_main: `/assets/images/icons/portfolio_order_release.svg`,
        imgs: [ `/assets/images/general/portfolio_order_release_1.jpg`,
                `/assets/images/general/portfolio_order_release_2.jpg`,
                `/assets/images/general/portfolio_order_release_3.jpg`,
                `/assets/images/general/portfolio_order_release_4.jpg`,]
    },
    // {
    //     website: null,
    //     content: [
    //         {title:"Project", description:`Music Player Sample App`}, 
    //         {title:`Description`, description:`I develop the front-end boilerplate code for a music player app for Family Hub devices. It was presented in the SDC 2019. I also wrote a book explaining each part of the code.`},
    //         {title:"Stack", description:`HTML, Javascript, CSS`}
    //     ],
    //     img_main: `/assets/images/icons/portfolio_srt.svg`,
    //     imgs: [ `/assets/images/general/portfolio_srt_1.png`,
    //             `/assets/images/general/portfolio_srt_2.png`,
    //             `/assets/images/general/portfolio_srt_3.png`]
    // },
    {
        website: null,
        content: [
            {title:"Project", description:`This website`}, 
            {title:`Description`, description:`I developed a serverless PWA application to show my skills to recruiters and maybe my future colleagues :)`},
            {title:"Stack", description:`ReactJS, Typescript, SASS, NodeJS, AWS`}
        ],
        img_className: `portfolio__container__wrapper__item__my-resume`,
        img_main: `/assets/images/icons/portfolio_my_resume.svg`,
        imgs: [ `/assets/images/general/portfolio_my_resume_1.jpg`,
                `/assets/images/general/portfolio_my_resume_2.jpg`,
                `/assets/images/general/portfolio_my_resume_3.jpg`,]
    },
    {
        website: null,
        content: [
            {title:"Project", description:`Samsung SRT Intranet`}, 
            {title:`Description`, description:`I developed new features, fixed errors, and upgraded interfaces to the Samsung SRT intranet`},
            {title:"Stack", description:`Bootstrap, JQuery, Django`}
        ],
        img_className: `portfolio__container__wrapper__item__samsung-intranet`,
        img_main: `/assets/images/icons/portfolio_srt.svg`,
        imgs: [ `/assets/images/general/portfolio_srt_1.jpg`,
                `/assets/images/general/portfolio_srt_2.jpg`,
                `/assets/images/general/portfolio_srt_3.jpg`]
    },
    {
        website: null,
        content: [
            {title:"Project", description:`Family Hub applications`}, 
            {title:`Description`, description:`I corrected errors, upgraded interfaces, and improved performance to the Family Hub applications (Spotify, Uber, TuneIn, Pandora)`},
            {title:"Stack", description:`ReactJS, Redux, Saga, TypeScript, Tizen SDK`}
        ],
        img_className: `portfolio__container__wrapper__item__family-hub`,
        img_main: `/assets/images/icons/portfolio_family_hub.svg`,
        imgs: [`/assets/images/general/portfolio_fh_1.jpg`,
            `/assets/images/general/portfolio_fh_2.jpg`,
            `/assets/images/general/portfolio_fh_3.jpg`,
            `/assets/images/general/portfolio_fh_5.jpg`]
    }
    ]
};

/* ======================================================= */
/* ======================================================= */
/* ======================================================= */
/* ======================================================= */
/* ======================================================= */
/* ======================================================= */
/* ======================================================= */
/* ======================================================= */
/* ======================================================= */
/* ======================================================= */
/* ======================================================= */
/* ======================================================= */

export const aboutThisPage = {
    p1: `All looks pretty nice! but can you really do all those things that you mentioned before?
    Sure thing! this website itself have a lot of these things, you may not know but I do actually
    use a lot of these things, so lets dive in!
    This website was develop with the following stack:`,

    p2: `HTML5
    CSS3 with SASS following BEM architect
    Javascript ES6+ with Typescript
    React
    Redux
    Redux-Sagas

    AWS - S3
    AWS - API Gateway
    AWS - Lambda
    AWS - Route 53

    Adobe Photoshop
    Adobe XD`

    ,
    p3: `
    You might say: what?! isn't this a static website? yeah! but in order to show you guys what can I do I make this website a little bit dynamic in the 
    following section.

    For this website I use it Redux and Sagas, not because it was necessary, just to let you guys see that I already know how to use it. 
    In fact, I will let you down here the global state of the app so you can see any update in the entire application, also you can modify
    some of the states directly.`,

    p4:`
    - HTML5
    I have knowledge about the new tags incorpored in HTML5. As a AdSense Editor, I have knowledge about the right way to use HTML tags for better SEO Onpage. 
    As well as the best practices to write HTML and have concern about accessibility.
    
    -CSS3
    This website use some of the new features of CSS as Grid layouts, the entire website was develop without any CSS/animation frameworks, all from scratch. 
    For this particular case, the CSS was coded under the BEM architect and using SASS pre-compiler.
    The website is responsive and fluid with the new viewport units.
    For this case as I'm trying to show the hottest stuff out there, maybe this site wont be fully supported in all the browsers, but for those that I already know are kinkys I will use some alternatives to make this visible for all.
    
    - Javascript
    Last but not least, JavaScript the entire website is using it as I'm using React so in the code you can see that I use the new features incopored 
    in ES6 as spread operators, arrow functions, generators, etc.
    
    -React
    This websites use React as a framework, I use the news React Hooks to develop functional components.
    `,    

    specialThanks: `Thank you so much to the following websites that help me out:

    https://colorhunt.co/palette/69667
    `
};


export const aboutMyself = {
    p1: `Hi, my name is Erick Cardiel, I'm a 24 y/o computer engineer, but besides all that kind of stuff, I really want you guys meet the real Erick not
    the business one.
    
    I like to dance, I actually go to a dance academy called Beat the Beat, I dance to any genre but any hip hop genre are my favorites
    you can see some videos of me dancing on my instagram :) 
    
    I do also like to walk my dogs every weeknd, I have a Chihuahua named Canabis and a French Puddle called Bombon :D
    
    I like to do exercice with calisthenics, I really enjoy do something for my body as it is the one that move me forward, I do also 
    like to read about nutrition and cook healthy food for myself.
    
    
    In music, I like all kinda stuff, what I listen to depends on my mood, but I can basically listen to Hip Hop R&B and Rock all day :) 
    
    Series, movies, anything with a plot twist, good soundtrack, or serial killers is all I can ask for.
    
    In the sports side I like to play tenis and when my body fat percent is higher than what I like, I love to go out and do some running.
    
    I'm a big fan of Hannibal, I saw the entire movies/serie/books, it develops my sociopath side >:D
    
    Besides that, when I'm not doing one of those things, I like to play League of Legends, I'm currently this is my nickname if you want to play: 
    
    And I do also always am learning new things about software development or embedded systems | electronics.
    `
};


export const quizz = {
    
    p1:`Congrats! claim your gift for our interview giving me the following code:

    This will give you a random and delicious fruit, what!? a fruit?! yeah you read it right :D fruits are important to 
    our body and health and they are delicious! I'm a big fan of health care, if you want to know me better go ahead to
    whos this guys section :) `
};

/* ========================================================= */



/*
====== Main ====== 

I'm a one trick pony with React I may not have experience with frameworks as Angular or Vue, but I consider that is better
to master one technologie stack and once I done that move to another. I still learning these technologies let's see what this
guy can do!


====== React ====== 

I started to code in React in 2018 for my Software Engineer class, my teacher Sergio Inzunza let the class choose between any popular framework | libraries back then, choices were: React, Angular and Vue. I look for some code snippets for each framework and, when I saw the one from React I completely fall in love with it, the fact that everything is encapsulated into a single file was the thing for me. From then to now, I became a one trick pony in web development.

So I have experience with components as classes and functional components. I also keep updating with the new features of react as React Hooks.

I have knowledge of the best practices of React, as create intelligent and dumb components, make reusable components and good understanding of the lifecycle of react components, React Routes. I always like to see videos from other programmers perspective and grab the things that I like to use.

You can see my logic of components right in the source code of this website in my GitHub account.

PS: I starting to study Testing so maybe in a few weeks a new view will be added here :)


====== Redux ====== 

Back in 2019 when I was in college, I started to develop an application as a school practitioner, when the app become bigger and bigger I got into a point when I said "Ok this is becoming a mess, I really need to understand Redux" back then I refuse to learn it because I took 9 classes in the semester, to be the nerdie guy who graduates at 4 and half years in my college, good old times

Nowadays, I really enjoy the process of planning every action, every reducer that would be in my apps, the process of abstraction make my excite of all the actions that my app will have, and the most important thing, that it would be easy to maintain, scale and test. With the experience, I develop good abstraction skills to assign what should be in a global state and what in a local state.

For this website I use it, not because it was necessary, just to let you guys see that I already know how to use it. In fact, I will let you down here the global state of the app so you can see any update in the entire application, also you can modify some of the states directly.



*/

