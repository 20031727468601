import React, {useEffect} from 'react';
import {useComponent} from '../utils/request'

function Outro() {
    const {data, postForm, getData} = useComponent();

    useEffect(() => {
        getData();
    }, [])


    function setText(e){
        if (e.target.files.length === 0) return false;
        const file = e.target.files[0];
        var span = document.getElementById("photo-selected");
        if (!/^image\//.test(file.type)) {
            span.innerText = 'File selected is not an image';
            span.style = 'color: red'
            return false;
        }
        span.innerText = e.target.files[0].name;
        span.style = 'color: white'
    }

    return (
        <section className="outro" tabIndex="0">
            <div className="outro__recruiters">
                <h3 className="outro__recruiters__title">Recruiters</h3>
                <div className="outro__recruiters__container"> 
                { data !== null && data.map((element, index)=>
                        <div key={element.name + index} className="outro__recruiters__container__item">
                            <img src={element.avatar} onError={e => e.target.src = "/assets/images/icons/user.svg"} alt={element.name + " photo"} className="clip-circle"/>
                            <div>{element.name}</div>
                            <div>{element.company}</div>
                        </div>
                )}
                </div>
            </div>

            <div className="outro__contact-form"> 
                <h3 className="outro__contact-form__title">Contact</h3>
                <form className="outro__contact-form__fields" name="outro-contact">
                    <label htmlFor="name">Name <em className="outro__contact-form__fields--required">*</em></label>
                    <input required type="text" id="name" name="name"/>
                    <label htmlFor="photo">Photo</label>
                    <div className="outro__contact-form__fields__photo-container">
                        <label className="outro__contact-form__fields__photo-container__input-container">
                            <input onChange={(e) => setText(e)} type="file" id="photo" name="photo" accept="image/*"/>Browse...
                        </label>
                        <span id="photo-selected">No file selected.</span>
                    </div>
                    <label htmlFor="company">Company <em className="outro__contact-form__fields--required">*</em></label>
                    <input required type="text" id="company" name="company"/>
                    <label htmlFor="email">Email <em className="outro__contact-form__fields--required">*</em></label>
                    <input required type="email" id="email" name="email"/>
                    <label htmlFor="message">Message <em className="outro__contact-form__fields--required">*</em></label>
                    <textarea required className="outro__contact-form__fields__message" type="text" id="message" name="message"/>
                    <div className="outro__contact-form__fields__submit"><input value="Submit" type="submit" onClick={ e => {e.preventDefault(); postForm();}}></input></div>     
                </form>
            </div>
        </section>
    );
}
           
export default Outro;