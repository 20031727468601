import React, { useState, useEffect } from 'react';

// todo: Add unique keys.

function Slider(props) {
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(function showSlides() {
    const numberOfElements = props.project.imgs.length;
    var slides = document.getElementsByClassName("project-" + props.project.content[0].description);
    var dots = document.getElementsByClassName("dot-" + props.project.content[0].description);
    var current = slideIndex;

    if (slideIndex > numberOfElements) {
      setSlideIndex(1);
      current = 1;
    }
    
    else if (slideIndex < 1) {
      setSlideIndex(numberOfElements);
      current = numberOfElements
    }

    for (let i = 0; i < numberOfElements; i++) {
        slides[i].className = slides[i].className.replace(" slider__slide--active", "");
        dots[i].className = dots[i].className.replace(" dots__dot--active", "");
      }

    slides[current - 1].className += " slider__slide--active";
    dots[current - 1].className += " dots__dot--active";
  }
  );
  console.log('Renderizando');
  return (
    <React.Fragment>
      <div className="slider">
        {
        props.project.imgs.map((element, index) =>
          <div className={"slider__slide project-" + props.project.content[0].description} key={"slide-" + index}>
            <img className="slider__slide__img" src={element} alt={"Slide" + index + " " + props.project.content[0].description}></img>
          </div>
        )
        }
        <div className="slider__prev-btn" onClick={() => setSlideIndex(slideIndex - 1)}>&#10094;</div>
        <div className="slider__next-btn" onClick={() => setSlideIndex(slideIndex + 1)}>&#10095;</div>
      </div>

      <div className="dots">
      {
        props.project.imgs.map((element, index) =>
          <span className={"dots__dot dot-" + props.project.content[0].description} key={"dot-" + index} onClick={() => setSlideIndex(index + 1)}></span>
        )
      }
      </div>
    </React.Fragment>
  );
}

export default Slider;