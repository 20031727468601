import React from 'react';
import {portfolio} from '../utils/constants'

function Portfolio(){
  return(
    <section className="portfolio" tabIndex="0">
      <div className="portfolio__container"> 
        <h2 className="portfolio__container__title">{portfolio.title}</h2>
        <div className="portfolio__container__wrapper">
        {
          portfolio.elements.map((element, index) =>
            <button key={element + index} className="portfolio__container__wrapper__item" data-open={"portfolio-modal" + index}>
              <img className={element.img_className} src={element.img_main} alt={element}/>
            </button>
          )
        }
        </div>
      </div>
    </section>
  );
}

export default Portfolio;