import React from 'react';
import Home from './components/main/home';
import Modal from './components/reusables/modal';
import {skills} from './components/utils/constants';
import {portfolio} from './components/utils/constants';

function App() {
  return (
    <React.Fragment>
      <Home/><section className="modals-container">
      {
        skills.items.map((element, index) =>
          <Modal key={"modal-skills" + index} info={skills.items[index].modal} modalId={"skills-modal" + index} modalType="normal"/>
        )
      }
      {
        portfolio.elements.map((element, index) =>
          <Modal key={"modal-portfolio" + index} portfolio={element} modalId={"portfolio-modal" + index} modalType="portfolio"/>
        )
      }
      </section>
    </React.Fragment>
  );
}

export default App;