export const setToastActive = function (toastName){
    var toast = document.getElementsByName(toastName)[0];
    toast.className += " toast--active";
    setTimeout(function(){ toast.className = toast.className.replace("toast--active", ""); }, 3000);
}

export const Attach = function(){
    const openEls = document.querySelectorAll("[data-open]");
    const closeEls = document.querySelectorAll("[data-close]");
    const isVisible = "is-visible"; 

    for (const el of openEls) {
      el.addEventListener("click", function() {
      const modalId = this.dataset.open;
      document.getElementById(modalId).classList.add(isVisible);
      });
    }
  
    for (const el of closeEls) {
      el.addEventListener("click", function() {
        this.parentElement.parentElement.parentElement.classList.remove(isVisible);
        this.parentElement.parentElement.classList.remove(isVisible);
        // TODO: Fix that ^
      });
    }
  
    document.addEventListener("click", e => {
      if (e.target === document.querySelector(".modal.is-visible")) {
        document.querySelector(".modal.is-visible").classList.remove(isVisible);
      }
    });
  
    document.addEventListener("keyup", e => {
      if (e.key === "Escape" && document.querySelector(".modal.is-visible")) {
        document.querySelector(".modal.is-visible").classList.remove(isVisible);
      }
    });

    // let sections = document.getElementsByTagName("section");
    // for(let section of sections){
    //   section.tabIndex = 0;
    // }
  }