import React from 'react';
import Slider from '../main/slider';

function Modal(props) {
  return (
    <div className={"modal"} id={props.modalId} data-animation="zoomInOut">
        {returnModal(props)}       
    </div>
  );
}


function returnModal(props) {
  switch(props.modalType) {
    case "normal":
      return (
      <div className="modal__dialog">
        <header className="modal__dialog__header">
          <h4 style={{display: 'inline-block'}}>{props.info.header}</h4>
          <button className="modal__dialog__header__close-modal" aria-label="close modal" data-close>x</button>
          </header>
          <hr className="modal__dialog__separator"></hr>
          <div className="modal__dialog__content"><p>{props.info.content}</p></div>
       
      </div>
    );

    case "portfolio":
      return (
      <div className="modal__portfolio">        
        <button className="modal__portfolio__close-modal" aria-label="close modal" data-close>x</button>
        <div className="modal__portfolio__content">
          <div className="modal__portfolio__content__info">
            {props.portfolio.content.map((element, index) => 
            <div key={element + index} className="modal__portfolio__content__info__wrapper">
              <div className="modal__portfolio__content__info__title">{element.title}</div>
              <div className="modal__portfolio__content__info__description">{element.description}</div> 
            </div>
            )}
            {
              props.portfolio.website !== null?
              <div className="modal__portfolio__content__info__button">
                <a href="www.google.com"><button className="btn btn--size-mid btn--color-red"><span>View Website</span></button></a>
              </div> : null
              }
          </div>
          <div className="modal__portfolio__content__slider"> <Slider project={props.portfolio}/></div>
        </div>
      </div>
      );

    default:
        return (<></>);
        
  }
}

export default Modal;