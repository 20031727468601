import { aboutMe } from '../utils/constants';
import React from 'react';

function AboutMe() {
  return (
    <section className="about-me" tabIndex="0">
      <div className="about-me__container">
        <div className="about-me__container__summary">
          <h2 className="about-me__container__summary__title">{aboutMe.title}</h2>
          <div className="about-me__container__summary__description">{aboutMe.content}</div>
          <div className="about-me__container__summary__img">
            <img src="/main_my_photo.jpg" alt="A really handsome man :)"></img>
          </div>
        </div>
        
        <div className="about-me__container__contact">
          <div className="about-me__container__contact__wrapper">
          {
            aboutMe.contact.items.map(element => 
              <div className="about-me__container__contact__wrapper__item" key={element.imgAlt}>
                <a key={element.imgAlt} href={element.url} target="_blank" rel="noopener noreferrer">
                <div className="about-me__container__contact__wrapper__item__wrapper-img"><img src={element.imgPath} alt={element.imgAlt}/>{element.text}</div>
              </a>
              </div>
            )
          }
          </div>
        </div>
      </div>
    </section>
  );
}
  
export default AboutMe;