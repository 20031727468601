import {useState} from 'react';
import {setToastActive} from '../common';
var apiUrl = "https://39v7c5e5z0.execute-api.us-east-2.amazonaws.com/Recruiters";


export function useComponent() {
    const [data, setData] = useState(null);
    
    const getData = () => {
      fetch(apiUrl).then(response => response.json()).then(json => {
        setData(json);
    });
    }

    const validatePhoto = photo => {
      if(!photo || !/^image\//.test(photo.type)) return false;
      return true;
    } 

    const postForm = () => {
      let form = document.forms["outro-contact"];
      form.reportValidity();

      let name = form["name"].value;
      let photo = form["photo"]?.files[0];
      let company = form["company"].value;
      let message = form["message"].value;
      let email = form["email"].value;

      if(form.checkValidity()) {
        if(validatePhoto(photo)){
        let reader = new FileReader();
        var jsonData = {"name":name, "avatar": null, "company": company, "message": message, "email": email};      
        reader.onload = () => {
          jsonData.avatar = reader.result;
          makeRequest(jsonData,form);
          }
        reader.readAsDataURL(photo);
      }   
      else{
        setToastActive("form-validate-image");
      }
    }
  }

  function makeRequest(jsonData, form) {
    let bodyData = JSON.stringify(jsonData);  
            var header = {
              method: "POST",
              body: bodyData,
              headers:{
                "Content-Type": "application/json"
              },
              mode: "cors"
            };

            fetch(apiUrl, header).then(response => {
              if (!response.ok) {
                setToastActive("form-error");
              } 
              else {
                getData();
                setToastActive("form-ok");
                form.reset();
              }       
            }).catch(function(err) {
            setToastActive("form-error");
        });
    }

  return {
    data, 
    getData,
    postForm
  };
};