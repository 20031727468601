import React, { useEffect } from 'react';
import Intro from './intro';
import AboutMe from './aboutMe';
import Skills from './skills';
import EmployeeBackground from './employeeBackground';
import Portfolio from './portfolio';
import {Attach} from '../common';
import Outro from './outro';
// import Quizz from './quizz';
import Snackbar from '../reusables/snackbar';

function Home() {

  useEffect(()=> Attach(), []);

  return (
    <React.Fragment>
        <Intro/>
        <AboutMe/>
        <Skills/>
        <EmployeeBackground/>
        <Portfolio/>
        {/* <Quizz/> */}
        <Outro/>
        <section className="toasts_container">
        <Snackbar text="Form sent succesfully!" id="form-ok" icon="toast_ok"/>
        <Snackbar text="Something went wrong in server :(" id="form-error" icon="toast_error"/>
        <Snackbar text="Please add a valid image file" id="form-validate-image" icon="toast_error"/>
        </section>
    </React.Fragment>
  );
}

export default Home;