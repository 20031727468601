import React from 'react';

function Snackbar(props){
    return(
      <React.Fragment>
        <div className={"toast"} name={props.id}>
          <div className="toast__container">
              <img className="toast__container__icon" src={"/assets/images/icons/" + props.icon + ".svg"} alt="Toast icon"></img>
              <div className="toast__container__description small-text" >{props.text}</div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default Snackbar;