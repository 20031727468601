import React from 'react'
import {employeeBackground} from '../utils/constants'

function EmployeeBackground() {
  return (
    <section className="employee-background" tabIndex="0">
      <div className="employee-background__experience">
        <h2 className="employee-background__experience__title">Experience</h2>
        <div className="employee-background__experience__wrapper-img">
          <img className="employee-background__experience__wrapper-img__samsung" src="/assets/images/icons/employee-background_samsung_2.svg" alt="samsung_logo"></img>
          <img className="employee-background__experience__wrapper-img__ntt" src="/assets/images/icons/employee-background_ntt-data.svg" alt="ntt-data_logo"></img>
        </div>
        <div className="employee-background__experience__content"> 
          <div className="employee-background__experience__content__title text">{employeeBackground.experience.titleSamsung} <span className="small-text">{employeeBackground.experience.periodSamsung}</span> </div> 
          <div className="employee-background__experience__content__title text">{employeeBackground.experience.titleNtt} <span className="small-text">{employeeBackground.experience.periodNtt}</span> </div> 
          <div className="employee-background__experience__content__description text">{employeeBackground.experience.description}</div> 
        </div>
      </div>

      <div className="employee-background__education">
        <h2 className="employee-background__education__title">Education</h2>
        <div className="employee-background__education__wrapper-img"><img className="employee-background__education__wrapper-img__img" src="/assets/images/icons/employee-background_uabc.svg" alt="education_logo"></img></div>
        <div className="employee-background__education__content"> 
          <div className="employee-background__education__content__title text">{employeeBackground.education.title}</div> 
          <div className="employee-background__education__content__degree small-text">{employeeBackground.education.degree}</div> 
          <div className="employee-background__education__content__period small-text">{employeeBackground.education.period}</div> 
          <div className="employee-background__education__content__description text">{employeeBackground.education.description}</div> 
        </div>
      </div>

    </section>
  );
}

export default EmployeeBackground;